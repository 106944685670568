import React, { useState, useEffect } from 'react';
import Button from "./ui/Button"

const CareerPage = () => {
  const [jobs, setJobs] = useState([
    // {
    //   id: 1,
    //   title: "Frontend Developer",
    //   location: "San Francisco, CA (Remote)",
    //   description: "We're looking for a talented frontend developer with experience in React and modern JavaScript frameworks to join our team.",
    //   tags: ["React", "JavaScript", "Tailwind CSS", "Full-time"],
    //   datePosted: "March 1, 2025",
    //   tillDate: "April 15, 2025",
    //   applyLink: "https://company.com/apply/frontend-developer"
    // },
    // {
    //   id: 2,
    //   title: "UX/UI Designer",
    //   location: "New York, NY (Hybrid)",
    //   description: "Join our creative team to design beautiful and intuitive user experiences for our growing product line.",
    //   tags: ["Figma", "UI Design", "User Testing", "Full-time"],
    //   datePosted: "March 5, 2025",
    //   tillDate: "April 30, 2025",
    //   applyLink: "https://company.com/apply/ux-ui-designer"
    // }
  ]);

  // Helper function to convert string date to Date object
  const parseDate = (dateStr) => {
    const [month, day, year] = dateStr.split(' ');
    const monthIndex = ["January", "February", "March", "April", "May", "June", 
                        "July", "August", "September", "October", "November", "December"]
                        .findIndex(m => m.startsWith(month));
    return new Date(parseInt(year), monthIndex, parseInt(day.replace(',', '')));
  };

  // Filter out expired jobs when component mounts and on daily check
  useEffect(() => {
    const filterExpiredJobs = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set to beginning of day for comparison
      
      const activeJobs = jobs.filter(job => {
        const endDate = parseDate(job.tillDate);
        return endDate >= today;
      });
      
      if (activeJobs.length !== jobs.length) {
        setJobs(activeJobs);
      }
    };

    // Run immediately when component mounts
    filterExpiredJobs();
    
    // Set up a daily check for expired jobs
    // In a real application, you might want to use a more sophisticated approach
    const intervalId = setInterval(filterExpiredJobs, 86400000); // 24 hours in milliseconds
    
    return () => clearInterval(intervalId); // Clean up on unmount
  }, [jobs]);

  return (
    <div className="bg-gray-50 min-h-screen pt-16">
      {/* Header section */}
      <header className="website-custom-color text-white">
        <div className="max-w-7xl mx-auto px-4 py-20 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl">
            Join Our Team
          </h1>
          <p className="mt-4 text-xl max-w-2xl mx-auto">
            Discover exciting opportunities and grow your career with us
          </p>
        </div>
      </header>

      {/* Main content */}
      <main className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Open Positions</h2>
          <div className="mt-2 w-20 h-1 website-custom-color mx-auto"></div>
        </div>

        {jobs.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            {jobs.map((job) => (
              <div 
                key={job.id} 
                className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:shadow-lg hover:-translate-y-1"
              >
                <div className="p-6 bg-gray-50 border-b border-gray-100">
                  <h3 className="text-xl font-semibold text-gray-900">{job.title}</h3>
                  <div className="flex items-center mt-2 text-gray-600">
                    <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    </svg>
                    <span className="text-sm">{job.location}</span>
                  </div>
                </div>
                
                <div className="p-6">
                  <p className="text-gray-700 mb-4">{job.description}</p>
                  
                  <div className="flex flex-wrap gap-2 mb-6">
                    {job.tags.map((tag, index) => (
                      <span 
                        key={index} 
                        className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-xs"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex flex-col space-y-1">
                      <span className="text-sm text-gray-500">Posted: {job.datePosted}</span>
                      <span className="text-sm text-gray-500">Apply by: {job.tillDate}</span>
                    </div>
                  </div>
                  
                  <div className="flex justify-end">
                    <a 
                      href={job.applyLink} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <Button 
                        className="text-white font-medium py-2 px-4 rounded transition-colors duration-300" 
                        text={"Apply Now"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-md p-12 text-center">
            <svg className="w-16 h-16 mx-auto text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
            <h3 className="mt-4 text-xl font-medium text-gray-900">No open positions at this time</h3>
            <p className="mt-2 text-gray-600">We'll post new job opportunities as soon as they become available. Check back soon!</p>
          </div>
        )}
      </main>

      {/* Footer with contact info */}
      <footer className="bg-gray-100 mt-12">
        <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-800">Can't find what you're looking for?</h3>
            <p className="mt-2 text-gray-600">Send your resume to <a href="mailto:info@vangtechnologies.com" className="text-blue-600 hover:underline">info@vangtechnologies.com</a></p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CareerPage;