import React, { useState } from "react";
import Button from "./ui/Button";
import axios from "axios";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    address: "",
    business: "",
    service: "",
    message: "",
  });

  const [errorMessage, setErrorMessage] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrorMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Form validation logic
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone_number ||
      !formData.address ||
      !formData.business ||
      !formData.service ||
      !formData.message
    ) {
      setErrorMessage(true);
      return;
    }

    setSending(true);

    try {
      // Replace with your actual form submission endpoint
      await axios.post("https://formspree.io/f/xpwaqvva", formData);

      setSent(true);
      setFormData({
        name: "",
        email: "",
        phone_number: "",
        address: "",
        business: "",
        service: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      setErrorMessage("Error sending email. Please try again later.");
    } finally {
      setSending(false);
    }
  };

  // Custom CSS to remove spinner arrows from number inputs
  const inputStyles = `
    /* For Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* For Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  `;

  return (
    <section className="py-16 md:py-24 bg-white dark:bg-gray-900">
      {/* Include the style tag to apply the custom CSS */}
      <style>{inputStyles}</style>

      <div className="container mx-auto px-4">
        {/* Header Section with Call to Action */}
        <div className="max-w-4xl mx-auto mb-12 text-center">
          <span className="inline-block mb-4 px-4 py-1.5 text-xs font-medium tracking-wider uppercase bg-blue-50 text-blue-600 dark:bg-blue-900/30 dark:text-blue-300 rounded-full transition-all">
            GET IN TOUCH
          </span>
          <h2 className="mb-4 text-4xl sm:text-5xl md:text-6xl font-bold leading-tight text-gray-900 dark:text-white">
            Contact Us
          </h2>
          <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8">
            Have questions or need assistance? We're here to help.
          </p>

          {/* Call to Action - Fixed for responsiveness */}
          <div className="bg-blue-50 p-6 rounded-xl mb-8 max-w-3xl mx-auto">
            <h3 className="text-xl font-semibold text-blue-700 dark:text-blue-300 mb-2">
              Ready to Transform Your Business?
            </h3>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Our team of experts is ready to help you implement the perfect
              solution for your needs. Fill out the form below or call us
              directly.
            </p>
            <a
              href="tel:+919738425496"
              className="inline-block w-full sm:w-auto"
            >
              <Button
                text="Call Us: 9738425496"
                className="w-full sm:w-auto flex justify-center items-center px-5 py-3 text-white font-medium rounded-lg transition-colors duration-300"
                svg={
                  <svg
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    ></path>
                  </svg>
                }
              />
            </a>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 max-w-6xl mx-auto">
          {/* Map Section */}
          <div className="w-full h-full min-h-80 rounded-2xl overflow-hidden shadow-sm border border-gray-100 dark:border-gray-700">
            <div className="w-full h-full min-h-80">
              <iframe
                title="VANG Technologies Location"
                className="w-full h-80 lg:h-full border-0"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15551.903687059796!2d77.58272347048344!3d12.973391821480387!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae160e24a33c53%3A0xa8784e7dfd7102ef!2sVANG%20TECHNOLOGIES%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1722453007131!5m2!1sen!2sin&disableDefaultUI=true&gestureHandling=cooperative&maptype=roadmap&style=feature:poi|visibility:off"
                loading="lazy"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          {/* Contact Form */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm p-6 md:p-8 border border-gray-100 dark:border-gray-700">
            <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
              Send us a Message
            </h3>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="mb-2">
                <label
                  htmlFor="name"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Your Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name..."
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs dark:bg-gray-700 dark:text-white"
                  value={formData.name}
                  onChange={handleChange}
                />
                <div className="h-5 mt-1">
                  {errorMessage && !formData.name && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please enter your Name
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-2">
                <label
                  htmlFor="email"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Your Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="john@example.com"
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs dark:bg-gray-700 dark:text-white"
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="h-5 mt-1">
                  {errorMessage && !formData.email && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please enter your email address
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-2">
                <label
                  htmlFor="phone_number"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Phone Number:
                </label>
                <input
                  type="number"
                  id="phone_number"
                  name="phone_number"
                  placeholder="Phone Number..."
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs dark:bg-gray-700 dark:text-white"
                  value={formData.phone_number}
                  onChange={handleChange}
                />
                <div className="h-5 mt-1">
                  {errorMessage && !formData.phone_number && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please enter your Mobile Number
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-2">
                <label
                  htmlFor="address"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Address:
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address..."
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs dark:bg-gray-700 dark:text-white"
                  value={formData.address}
                  onChange={handleChange}
                />
                <div className="h-5 mt-1">
                  {errorMessage && !formData.address && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please enter your Address
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-2">
                <label
                  htmlFor="business"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Business Name:
                </label>
                <input
                  type="text"
                  id="business"
                  name="business"
                  placeholder="Business Name..."
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs dark:bg-gray-700 dark:text-white"
                  value={formData.business}
                  onChange={handleChange}
                />
                <div className="h-5 mt-1">
                  {errorMessage && !formData.business && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please enter your Business Name
                    </p>
                  )}
                </div>
              </div>

              {/* New Service Name Field */}
              <div className="mb-2">
                <label
                  htmlFor="service"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Service Required:
                </label>
                <input
                  type="text"
                  id="service"
                  name="service"
                  placeholder="Point of Sale, Front office, etc..."
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs dark:bg-gray-700 dark:text-white"
                  value={formData.service}
                  onChange={handleChange}
                />
                <div className="h-5 mt-1">
                  {errorMessage && !formData.service && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please specify the service you're interested in
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="message"
                  className="block text-xs font-medium text-gray-700 dark:text-gray-300 mb-2"
                >
                  Message:
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="mt-1 block w-full py-3 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-xs resize-none dark:bg-gray-700 dark:text-white"
                  placeholder="Your message here..."
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <div className="h-5 mt-1">
                  {errorMessage && !formData.message && (
                    <p className="text-red-600 font-light lg:text-sm text-xs">
                      Please enter your message
                    </p>
                  )}
                </div>
              </div>

              <Button
                type="submit"
                className={`w-full px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-medium rounded-lg transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
                  sending ? "opacity-50 cursor-not-allowed" : ""
                }`}
                text={sending ? "Sending..." : "Send Message"}
                disabled={sending}
              />

              <div className="h-9 mt-3 flex flex-col items-center">
                {sent && (
                  <p className="text-green-600 font-light lg:text-sm text-xs">
                    Your message has been successfully sent!
                  </p>
                )}
              </div>
            </form>

            <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-blue-600 dark:text-blue-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      +91 9738425496
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-blue-600 dark:text-blue-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                    info@vangtechnologies.in
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg
                      className="w-5 h-5 text-blue-600 dark:text-blue-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      ></path>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                      1,2,3, VANG TECHNOLOGIES PVT LTD, opp. Movie Land Theater,
                      Gandhi Nagar, Bengaluru, Karnataka 560009
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
