const Productinfo = [
  {
    id: "1",
    title: "Front Office Management",
    content:
      "This software streamlines hotel reception by optimizing check-ins, reservations, and desk efficiency. It automates tasks and centralizes guest information, enabling personalized service and improved operational performance.",
    tagline:
      "This software streamlines billing, orders, and customer service for improved efficiency.",
    img1: "/Images/front-office-1.png",
    img2: "/Images/front-office-2.png",
    img3: "/Images/front-office-3.png",
    img1_1: "/Images/front-office-1-1.png",
    img2_2: "/Images/front-office-2-2.png",
    img3_3: "/Images/front-office-3-3.png",
    bannerImage: "/Images/front-office-banner.png", // Banner image for parallax effect
    img4: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#3498db" rx="20" />
        <rect
          x="80"
          y="30"
          width="60"
          height="90"
          fill="#ecf0f1"
          stroke="#2c3e50"
          stroke-width="4"
        />
        <circle cx="95" cy="75" r="5" fill="#2c3e50" />
        <circle
          cx="220"
          cy="75"
          r="20"
          fill="none"
          stroke="#f39c12"
          stroke-width="6"
        />
        <rect x="200" y="75" width="30" height="10" fill="#f39c12" />
        <rect x="210" y="85" width="10" height="10" fill="#f39c12" />
      </svg>
    ),

    img1heading: "Room Access Management",
    img1content:
      "Contains various functional buttons like REFRESH, CLEAR, GROUP CHECK-IN, REPORTS, and more, catering to different operational needs. A key feature that could be integrated is DOOR LOCKING, allowing staff to control electronic room locks directly from the system, enhancing both security and convenience in managing room access.",
    img5: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#2c3e50" rx="20" />
        <rect
          x="80"
          y="30"
          width="60"
          height="90"
          fill="#ecf0f1"
          stroke="#ffffff"
          stroke-width="4"
        />
        <circle cx="95" cy="75" r="5" fill="#ffffff" />
        <circle
          cx="220"
          cy="75"
          r="20"
          fill="none"
          stroke="#f39c12"
          stroke-width="6"
        />
        <line
          x1="220"
          y1="55"
          x2="220"
          y2="45"
          stroke="#f39c12"
          stroke-width="4"
        />
        <line
          x1="220"
          y1="95"
          x2="220"
          y2="105"
          stroke="#f39c12"
          stroke-width="4"
        />
        <line
          x1="200"
          y1="75"
          x2="190"
          y2="75"
          stroke="#f39c12"
          stroke-width="4"
        />
        <line
          x1="240"
          y1="75"
          x2="250"
          y2="75"
          stroke="#f39c12"
          stroke-width="4"
        />
        <circle cx="220" cy="75" r="8" fill="#f39c12" />
      </svg>
    ),
    img2heading: "Room Management Automation",
    img2content:
      "Displays Room and Occupancy Details, including a grid for room types and availability, color-coded by status. The Hotel Occupancy Tracker shows the current occupancy rate (37.71%) and detailed guest information. A Detailed Information Panel provides additional insights on guests or operations. Integrating Power Automation could streamline tasks like updating room statuses or notifying housekeeping, enhancing efficiency.",
    img6: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#34495e" rx="20" />
        <rect
          x="80"
          y="40"
          width="60"
          height="70"
          fill="#ecf0f1"
          stroke="#ffffff"
          stroke-width="3"
        />
        <rect x="90" y="50" width="10" height="10" fill="#2c3e50" />
        <rect x="110" y="50" width="10" height="10" fill="#2c3e50" />
        <rect x="90" y="70" width="10" height="10" fill="#2c3e50" />
        <rect x="110" y="70" width="10" height="10" fill="#2c3e50" />
        <rect x="100" y="90" width="20" height="20" fill="#f39c12" />
        <rect
          x="200"
          y="50"
          width="60"
          height="50"
          fill="none"
          stroke="#f39c12"
          stroke-width="4"
        />
        <line
          x1="210"
          y1="60"
          x2="240"
          y2="60"
          stroke="#f39c12"
          stroke-width="3"
        />
        <line
          x1="210"
          y1="70"
          x2="230"
          y2="70"
          stroke="#f39c12"
          stroke-width="3"
        />
        <circle cx="245" cy="70" r="5" fill="#f39c12" />
        <line
          x1="210"
          y1="80"
          x2="230"
          y2="80"
          stroke="#f39c12"
          stroke-width="3"
        />
        <circle cx="245" cy="80" r="5" fill="#f39c12" />
      </svg>
    ),
    img3heading: "Hotel Dashboard Integration",
    img3content:
      "The interface includes a Bar Chart for tracking revenue or bookings, a Sidebar Menu with sections like DASHBOARD, RESERVATIONS, and REPORTS, and a Main Display Area for detailed information. The Summary Section shows current occupancy and guest count. Third-party integration enhances functionality with services like ID card scanning, image/thumb capturing, SMS, WhatsApp, and PhonePe for identity verification, communication, and payment processing.",
    stats: [
      {
        text: "Hotels Nationwise",
        number: "100+",
      },
      {
        text: "Billings Daily",
        number: "5L+",
      },
      {
        text: "Setup Time",
        number: ">1 day",
      },
    ],
    testimonials: [
      {
        quote:
          "Front Office Management has transformed our reception process. Check-ins are faster and our staff can focus on hospitality instead of paperwork.",
        author: "Priya Sharma, Hotel Manager",
      },
      {
        quote:
          "The centralized guest information system has helped us provide truly personalized service. Our repeat bookings have increased by 30%.",
        author: "Rajiv Mehta, Resort Owner",
      },
    ],
    faqs: [
      {
        question: "How much training does the staff need?",
        answer:
          "Our intuitive interface requires minimal training. Most staff members become proficient within 2-3 days of implementation.",
      },
      {
        question: "Can it integrate with our existing PMS?",
        answer:
          "Yes, our system is designed to seamlessly integrate with most popular Property Management Systems.",
      },
      {
        question: "Is the software cloud-based or on-premise?",
        answer:
          "We offer both options to suit your business requirements. The cloud version includes automatic updates and remote access features.",
      },
    ],
  },
  {
    id: "2",
    title: "Point of Sale",
    content:
      "This software streamlines restaurant operations by tracking orders, reducing errors, and providing real-time sales and inventory insights. It boosts productivity and enhances customer satisfaction through automation and seamless system integration.",
    tagline:
      "This software optimizes orders, reduces errors, and boosts productivity and satisfaction.",
    bannerImage: "/Images/pos-banner.png", // Banner image for parallax effect
    img1: "/Images/pos-1.png",
    img1heading: "Hotel & Restaurant Management",
    img1content:
      "The system includes a Room Availability Grid for tracking room statuses, a Transaction Menu for functions like POS, Room Allocation, Settlement, and Billing, and a Miscellaneous Menu for managing guest check-ins/outs and city ledger activities. The Captain App streamlines restaurant orders, improving service speed and accuracy. It also features UPI Payment Integration, enabling secure, fast transactions and offering a convenient payment method for customers.",
    img4: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#e74c3c" rx="20" />
        <rect x="40" y="40" width="100" height="70" fill="#ecf0f1" rx="5" />
        <rect x="50" y="50" width="80" height="15" fill="#3498db" rx="2" />
        <rect x="50" y="75" width="30" height="25" fill="#2ecc71" rx="2" />
        <rect x="90" y="75" width="30" height="25" fill="#f39c12" rx="2" />

        <rect x="170" y="40" width="90" height="70" fill="#ecf0f1" rx="5" />
        <circle cx="215" cy="60" r="15" fill="#9b59b6" />
        <rect x="190" y="85" width="50" height="15" fill="#2c3e50" rx="2" />

        <path
          d="M60 130 L80 110 L100 125 L120 105 L140 115"
          stroke="#2ecc71"
          stroke-width="3"
          fill="none"
        />
        <path
          d="M190 130 L210 120 L230 125 L250 110"
          stroke="#e74c3c"
          stroke-width="3"
          fill="none"
        />
      </svg>
    ),
    img2: "/Images/pos-2.png",
    img2heading: "Master Menu & KOT",
    img2content:
      "The Master Menu offers options for creating guests, tariffs, vouchers, and packages, along with managing room statuses and ledger creation for financial accounts. It includes setup options for rate and member configurations, user creation for system management, and now integrates Network KOT Printing for seamless kitchen order ticket printing across multiple networked devices, enhancing efficiency in food service operations",
    img5: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#27ae60" rx="20" />
        <rect x="30" y="30" width="80" height="90" fill="#ecf0f1" rx="5" />
        <rect x="40" y="40" width="60" height="10" fill="#34495e" rx="2" />
        <rect x="40" y="55" width="60" height="10" fill="#34495e" rx="2" />
        <rect x="40" y="70" width="60" height="10" fill="#34495e" rx="2" />
        <rect x="40" y="85" width="60" height="10" fill="#34495e" rx="2" />
        <rect x="40" y="100" width="60" height="10" fill="#34495e" rx="2" />
        <rect x="140" y="30" width="130" height="90" fill="#ecf0f1" rx="5" />
        <rect x="150" y="40" width="110" height="15" fill="#3498db" rx="2" />
        <line
          x1="150"
          y1="65"
          x2="260"
          y2="65"
          stroke="#95a5a6"
          stroke-width="2"
        />
        <rect x="150" y="70" width="70" height="10" fill="#2c3e50" rx="2" />
        <rect x="230" y="70" width="20" height="10" fill="#e74c3c" rx="2" />
        <rect x="150" y="85" width="70" height="10" fill="#2c3e50" rx="2" />
        <rect x="230" y="85" width="20" height="10" fill="#e74c3c" rx="2" />
        <rect x="150" y="100" width="70" height="10" fill="#2c3e50" rx="2" />
        <rect x="230" y="100" width="20" height="10" fill="#e74c3c" rx="2" />
        <path
          d="M110 75 C120 75, 125 50, 140 50"
          stroke="#f39c12"
          stroke-width="3"
          fill="none"
        />
        <circle cx="110" cy="75" r="5" fill="#f39c12" />
        <circle cx="140" cy="50" r="5" fill="#f39c12" />
      </svg>
    ),
    img3: "/Images/pos-3.png",
    img3heading: "Sales Data & Kitchen Display",
    img3content:
      "The system tracks Monthly Sales, Day Sales, Cash, and Credit sales, with visual insights provided through a Bar Chart displaying daily sales trends and a Pie Chart showing cash vs. credit sales distribution. It also features a Display System for Kitchen to streamline order communication and improve operational efficiency.",
    img6: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#16a085" rx="20" />
        <rect x="30" y="30" width="100" height="90" fill="#ecf0f1" rx="5" />
        <rect x="40" y="100" width="15" height="10" fill="#3498db" />
        <rect x="60" y="80" width="15" height="30" fill="#3498db" />
        <rect x="80" y="60" width="15" height="50" fill="#3498db" />
        <rect x="100" y="70" width="15" height="40" fill="#3498db" />
        <line
          x1="30"
          y1="110"
          x2="130"
          y2="110"
          stroke="#34495e"
          stroke-width="2"
        />
        <line
          x1="30"
          y1="30"
          x2="30"
          y2="110"
          stroke="#34495e"
          stroke-width="2"
        />
        <circle cx="195" cy="50" r="25" fill="#ecf0f1" />
        <path d="M195 50 L195 25 A25 25 0 0 1 217 62 Z" fill="#e74c3c" />
        <path d="M195 50 L217 62 A25 25 0 0 1 173 62 Z" fill="#3498db" />
        <path d="M195 50 L173 62 A25 25 0 0 1 195 25 Z" fill="#f39c12" />
        <rect x="170" y="85" width="100" height="45" fill="#ecf0f1" rx="5" />
        <rect x="175" y="90" width="90" height="10" fill="#e74c3c" rx="2" />
        <rect x="175" y="105" width="60" height="8" fill="#34495e" rx="2" />
        <rect x="245" y="105" width="15" height="8" fill="#27ae60" rx="2" />
        <rect x="175" y="118" width="60" height="8" fill="#34495e" rx="2" />
        <rect x="245" y="118" width="15" height="8" fill="#f39c12" rx="2" />
      </svg>
    ),
    stats: [
      {
        text: "Restaurants Served",
        number: "500+",
      },
      {
        text: "Orders Processed Daily",
        number: "10K+",
      },
      {
        text: "Average Order Time",
        number: "<3 min",
      },
    ],
    testimonials: [
      {
        quote:
          "The POS system has drastically reduced our order errors and improved kitchen communication. Service is faster and customers are happier.",
        author: "Ankit Patel, Restaurant Owner",
      },
      {
        quote:
          "The real-time sales insights have been game-changing for inventory management. We've reduced waste by 25% since implementation.",
        author: "Neha Singh, F&B Manager",
      },
    ],
    faqs: [
      {
        question: "Does the POS work offline?",
        answer:
          "Yes, our POS has offline functionality that syncs with the server once connection is restored.",
      },
      {
        question: "Can we customize the menu and categories?",
        answer:
          "Absolutely! The system offers full customization of menu items, categories, modifiers, and combos.",
      },
      {
        question: "How does the KOT system improve kitchen operations?",
        answer:
          "Network KOT Printing sends orders directly to kitchen displays or printers, eliminating manual trips and reducing preparation times by up to 40%.",
      },
    ],
    img1_1: "/Images/pos-1-1.png", // Added img1_1
    img2_2: "/Images/pos-2-2.png", // Added img2_2
    img3_3: "/Images/pos-3-3.png", // Added img3_3
  },
  {
    id: "3",
    title: "MRP Management Software",
    content:
      "This retail pricing optimization software adjusts prices dynamically across multiple stores by analyzing market trends, competitor pricing, and internal metrics. Using advanced algorithms, it recommends optimal pricing, considering demand elasticity and seasonality. It ensures consistent pricing updates, improving profitability and customer satisfaction through competitive, data-driven pricing decisions.",
    tagline:
      "Optimizing Pricing Strategies for Consistent Profitability and Competitive Advantage.",
    bannerImage: "/Images/mrp-banner.png", // Banner image for parallax effect
    img1: "/Images/mrp-1.png",
    img1heading: "Sales Dashboard Overview",
    img1content:
      "The Sales Dashboard includes a Date Selector to view data for specific periods, a Sales Data Table showing metrics like Monthly, Day, Card, Cash, and Credit Sales, and a Detailed Sales Table for transaction breakdowns. The toolbar offers quick access to functions and navigation.",
    img4: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#8e44ad" rx="20" />
        <rect x="30" y="30" width="240" height="20" fill="#ecf0f1" rx="5" />
        <rect x="40" y="35" width="60" height="10" fill="#3498db" rx="2" />
        <circle cx="120" cy="40" r="5" fill="#34495e" />
        <rect x="140" y="35" width="60" height="10" fill="#3498db" rx="2" />
        <circle cx="220" cy="40" r="5" fill="#34495e" />
        <rect x="235" y="35" width="25" height="10" fill="#e74c3c" rx="2" />
        <rect x="30" y="60" width="240" height="70" fill="#ecf0f1" rx="5" />
        <rect x="40" y="70" width="220" height="10" fill="#3498db" rx="2" />
        <line
          x1="40"
          y1="85"
          x2="260"
          y2="85"
          stroke="#7f8c8d"
          stroke-width="1"
        />

        <rect x="40" y="90" width="40" height="8" fill="#34495e" rx="2" />
        <rect x="90" y="90" width="30" height="8" fill="#e74c3c" rx="2" />
        <rect x="130" y="90" width="30" height="8" fill="#f39c12" rx="2" />
        <rect x="170" y="90" width="30" height="8" fill="#2ecc71" rx="2" />
        <rect x="210" y="90" width="30" height="8" fill="#3498db" rx="2" />

        <rect x="40" y="105" width="40" height="8" fill="#34495e" rx="2" />
        <rect x="90" y="105" width="30" height="8" fill="#e74c3c" rx="2" />
        <rect x="130" y="105" width="30" height="8" fill="#f39c12" rx="2" />
        <rect x="170" y="105" width="30" height="8" fill="#2ecc71" rx="2" />
        <rect x="210" y="105" width="30" height="8" fill="#3498db" rx="2" />
        <rect x="30" y="140" width="240" height="5" fill="#ecf0f1" rx="2" />
      </svg>
    ),
    img2: "/Images/mrp-2.png",
    img2heading: "Room and Occupancy Details",
    img2content:
      "The system displays Available Room Types in a table, detailing room availability, pricing, and other relevant information. The Hotel Occupancy Tracker provides an overview of occupied rooms, listing guests' names and related details. A visual representation, such as a progress bar or status indicator, shows the current occupancy levels, offering a clear view of room availability.",
    img5: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#2980b9" rx="20" />
        <rect x="30" y="30" width="110" height="90" fill="#ecf0f1" rx="5" />
        <rect x="35" y="35" width="100" height="15" fill="#34495e" rx="2" />
        <line
          x1="35"
          y1="55"
          x2="135"
          y2="55"
          stroke="#7f8c8d"
          stroke-width="1"
        />

        <rect x="35" y="60" width="40" height="10" fill="#3498db" rx="2" />
        <rect x="85" y="60" width="20" height="10" fill="#27ae60" rx="2" />
        <rect x="115" y="60" width="15" height="10" fill="#e74c3c" rx="2" />

        <rect x="35" y="75" width="40" height="10" fill="#3498db" rx="2" />
        <rect x="85" y="75" width="20" height="10" fill="#f39c12" rx="2" />
        <rect x="115" y="75" width="15" height="10" fill="#27ae60" rx="2" />

        <rect x="35" y="90" width="40" height="10" fill="#3498db" rx="2" />
        <rect x="85" y="90" width="20" height="10" fill="#27ae60" rx="2" />
        <rect x="115" y="90" width="15" height="10" fill="#e74c3c" rx="2" />

        <rect x="35" y="105" width="40" height="10" fill="#3498db" rx="2" />
        <rect x="85" y="105" width="20" height="10" fill="#27ae60" rx="2" />
        <rect x="115" y="105" width="15" height="10" fill="#27ae60" rx="2" />
        <rect x="160" y="30" width="110" height="90" fill="#ecf0f1" rx="5" />
        <rect x="165" y="35" width="100" height="15" fill="#34495e" rx="2" />

        <rect
          x="165"
          y="60"
          width="100"
          height="10"
          fill="#ecf0f1"
          stroke="#7f8c8d"
          stroke-width="1"
          rx="2"
        />
        <rect x="165" y="60" width="40" height="10" fill="#3498db" rx="2" />

        <rect x="165" y="75" width="70" height="8" fill="#34495e" rx="2" />
        <rect x="165" y="88" width="70" height="8" fill="#34495e" rx="2" />
        <rect x="165" y="101" width="70" height="8" fill="#34495e" rx="2" />

        <text x="230" y="80" font-family="Arial" font-size="10" fill="#e74c3c">
          37%
        </text>
      </svg>
    ),
    img3: "/Images/mrp-3.png",
    img3heading: "Analysis Tools",
    img3content:
      "The system includes Icons representing various functions like check-ins, reservations, room service, accounts, and more for easy navigation. Analysis Tools provide buttons or options for generating different reports or analyses, such as Night Audit, Monthly Sales Analysis, and Stock Movement, enabling efficient data review and decision-making.",
    img6: (
      <svg
        width="300"
        height="150"
        viewBox="0 0 300 150"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="300" height="150" fill="#c0392b" rx="20" />
        <circle cx="60" cy="40" r="15" fill="#ecf0f1" />
        <rect x="50" y="35" width="20" height="10" fill="#3498db" />

        <circle cx="100" cy="40" r="15" fill="#ecf0f1" />
        <path
          d="M95 40 L105 40 M100 35 L100 45"
          stroke="#27ae60"
          stroke-width="2"
        />

        <circle cx="140" cy="40" r="15" fill="#ecf0f1" />
        <rect x="135" y="35" width="10" height="10" fill="#f39c12" />

        <circle cx="180" cy="40" r="15" fill="#ecf0f1" />
        <path
          d="M175 40 A5 5 0 0 0 185 40"
          stroke="#8e44ad"
          stroke-width="2"
          fill="none"
        />
        <circle cx="180" cy="35" r="2" fill="#8e44ad" />

        <circle cx="220" cy="40" r="15" fill="#ecf0f1" />
        <path
          d="M215 40 L225 40 M220 35 L220 45"
          stroke="#e74c3c"
          stroke-width="2"
        />
        <rect x="40" y="70" width="220" height="60" fill="#ecf0f1" rx="5" />
        <rect x="50" y="80" width="60" height="15" fill="#3498db" rx="3" />
        <rect x="120" y="80" width="60" height="15" fill="#27ae60" rx="3" />
        <rect x="190" y="80" width="60" height="15" fill="#f39c12" rx="3" />

        <rect x="50" y="105" width="60" height="15" fill="#9b59b6" rx="3" />
        <rect x="120" y="105" width="60" height="15" fill="#e74c3c" rx="3" />
        <rect x="190" y="105" width="60" height="15" fill="#16a085" rx="3" />
      </svg>
    ),
    stats: [
      {
        text: "Price Updates Daily",
        number: "1000+",
      },
      {
        text: "Revenue Increase",
        number: "15%",
      },
      {
        text: "Implementation Time",
        number: "<1 week",
      },
    ],
    testimonials: [
      {
        quote:
          "This MRP software has completely transformed how we price our products. Our margins have improved by 12% in just three months.",
        author: "Vikram Malhotra, Retail Chain Director",
      },
      {
        quote:
          "The dynamic pricing capabilities have given us a significant edge in our competitive market. Implementation was smooth and the results were immediate.",
        author: "Sunita Jain, Operations Head",
      },
    ],
    faqs: [
      {
        question: "How does the system handle seasonal pricing?",
        answer:
          "Our algorithms automatically factor in seasonality patterns to suggest optimal pricing adjustments throughout the year.",
      },
      {
        question: "Can we set pricing rules and constraints?",
        answer:
          "Yes, you can establish minimum margins, maximum discounts, and other pricing rules to maintain your business policies.",
      },
      {
        question: "How often does the system update pricing recommendations?",
        answer:
          "The system analyzes data and updates recommendations daily, but you can configure it to run more frequently for fast-moving markets.",
      },
    ],
    img1_1: "/Images/pos-1-1.png", // Added img1_1
    img2_2: "/Images/pos-2-2.png", // Added img2_2
    img3_3: "/Images/pos-3-3.png", // Added img3_3
  },
];

export default Productinfo;
