import React, { useState, useEffect, useRef } from "react";
import Productinfo from "./Displayproductarray";
import { useParams, Link } from "react-router-dom";
import Button from "./ui/Button";
import Statistics from "./Statistics";
import ContactUs from "./Contactus";

function Displayproduct() {
  const { productId } = useParams();
  const product = Productinfo.find((item) => item.id === productId);
  const [activeImage, setActiveImage] = useState("");

  // Create refs for the sections we want to scroll to
  const contactRef = useRef(null);
  const featuresRef = useRef(null);

  useEffect(() => {
    if (product) {
      setActiveImage(product.img1_1);

      // Scroll to top when component mounts
      window.scrollTo(0, 0);
    }
  }, [product]);

  // Function to scroll to a specific ref
  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  if (!product) {
    return (
      <div className="flex flex-col justify-center items-center h-screen gap-4">
        <p className="text-2xl text-slate-700">Product not found</p>
        <Link to="/" className="text-blue-600 hover:text-blue-800 underline">
          Return to home
        </Link>
      </div>
    );
  }

  const thumbnails = [product.img1_1, product.img2_2, product.img3_3].filter(
    Boolean
  );
  const smallThumbnails = [product.img1, product.img2, product.img3];

  // Helper function to render SVG or image content - Fixed for better small screen handling
  const renderContentElement = (content) => {
    // If content is a React element (SVG), render it directly with responsive classes
    if (React.isValidElement(content)) {
      return (
        <div className="w-full h-full flex justify-center items-center overflow-hidden">
          {React.cloneElement(content, {
            className: `max-w-full max-h-full ${content.props.className || ""}`,
          })}
        </div>
      );
    }
    // Otherwise render as an image with proper responsive classes
    return (
      <img
        className="w-full h-full object-contain sm:object-cover"
        src={content}
        alt="Feature"
      />
    );
  };

  return (
    <div className="bg-white w-full">
      {/* Hero Section with Parallax Effect */}
      <div className="relative pt-20 overflow-hidden">
        <div className="container mx-auto px-4 h-full relative flex items-center">
          <h1 className="text-slate-800 text-4xl md:text-5xl lg:text-6xl font-bold max-w-3xl">
            {product.title}
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-6xl px-5 w-full mx-auto py-12">
        {/* Product Overview */}
        <div className="grid md:grid-cols-2 gap-12 mb-16 items-start">
          {/* Product Images */}
          <div className="flex flex-col gap-4">
            <div className="w-full h-96 rounded-lg overflow-hidden">
              <img
                src={activeImage}
                alt={product.title}
                className="w-full h-full object-fit"
              />
            </div>
            <div className="flex gap-4 mt-4 overflow-x-auto pb-2">
              {smallThumbnails.map((img, index) => (
                <button
                  key={index}
                  onClick={() => setActiveImage(img)}
                  className={`relative flex-shrink-0 w-24 h-24 rounded-lg overflow-hidden hover:opacity-90 transition-opacity ${
                    activeImage === img ? "shadow-md" : "opacity-70"
                  }`}
                >
                  <img
                    src={img}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                  {activeImage === img && (
                    <div className="absolute inset-0 border-2 border-blue-500 rounded-lg pointer-events-none"></div>
                  )}
                </button>
              ))}
            </div>
          </div>

          {/* Product Info */}
          <div className="flex flex-col gap-6 max-w-xl w-full">
            <div>
              <p className="website-custom-text font-medium mb-2">
                Premium Product
              </p>
              <h2 className="font-inter text-3xl md:text-4xl font-semibold tracking-tight text-slate-900 mb-4">
                {product.title}
              </h2>
              <p className="font-inter text-lg font-normal text-slate-600 mb-6">
                {product.content}
              </p>
              <div className="bg-slate-50 p-4 rounded-lg border border-slate-200 mb-6">
                <p className="italic text-slate-700">{product.tagline}</p>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <Button
                className="w-full sm:w-auto px-6 py-3 bg-slate-900 text-white border border-slate-900 rounded-xl font-medium transition-all duration-200 hover:bg-transparent hover:text-slate-900"
                text={"Apply For Free Demo"}
                onClick={() => scrollToRef(contactRef)}
              />
              <Button
                className="w-full sm:w-auto px-6 py-3 bg-transparent text-slate-900 border border-slate-300 rounded-xl font-medium transition-all duration-200 hover:border-slate-900"
                text={"Learn more"}
                onClick={() => scrollToRef(featuresRef)}
              />
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div className="mb-16" ref={featuresRef}>
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-3">Key Features</h2>
            <p className="text-slate-600 max-w-2xl mx-auto">
              Discover what makes our product stand out from the competition
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="bg-white rounded-xl overflow-hidden shadow transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px]">
              <div className="h-48 w-full overflow-hidden flex justify-center items-center">
                {renderContentElement(product.img4)}
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-slate-900 mb-3">
                  {product.img1heading}
                </h3>
                <p className="text-slate-600">{product.img1content}</p>
              </div>
            </div>

            <div className="bg-white rounded-xl overflow-hidden shadow transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px]">
              <div className="h-48 w-full overflow-hidden flex justify-center items-center">
                {renderContentElement(product.img5)}
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-slate-900 mb-3">
                  {product.img2heading}
                </h3>
                <p className="text-slate-600">{product.img2content}</p>
              </div>
            </div>

            <div className="bg-white rounded-xl overflow-hidden shadow transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px]">
              <div className="h-48 w-full overflow-hidden flex justify-center items-center">
                {renderContentElement(product.img6)}
              </div>
              <div className="p-6">
                <h3 className="text-xl font-semibold text-slate-900 mb-3">
                  {product.img3heading}
                </h3>
                <p className="text-slate-600">{product.img3content}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Statistics Section */}
        <div className="bg-gradient-to-r from-slate-50 to-blue-50 rounded-2xl p-8 md:p-12 mb-16">
          <h2 className="text-2xl md:text-3xl font-semibold text-center mb-10">
            The Numbers Speak for Themselves
          </h2>
          <Statistics stats={product.stats} />
        </div>

        {/* Testimonials Section */}
        {product.testimonials && product.testimonials.length > 0 && (
          <div className="mb-16 bg-slate-900 text-white rounded-2xl p-8 md:p-12">
            <h2 className="text-2xl md:text-3xl font-semibold text-center mb-8">
              What Our Customers Say
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              {product.testimonials.map((testimonial, index) => (
                <div key={index} className="bg-slate-800 p-6 rounded-xl">
                  <p className="italic mb-4">"{testimonial.quote}"</p>
                  <p className="font-semibold">— {testimonial.author}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Contact Section */}
        <div
          className="bg-white rounded-2xl shadow-xl p-8 md:p-12 border border-slate-100"
          ref={contactRef}
        >
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold mb-3">Ready to Get Started?</h2>
            <p className="text-slate-600 max-w-2xl mx-auto">
              Our team is ready to help you implement this solution in your
              business
            </p>
          </div>
          <ContactUs />
        </div>
      </div>

      {/* FAQ Section */}
      {product.faqs && product.faqs.length > 0 && (
        <div className="bg-slate-50 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">
              Frequently Asked Questions
            </h2>
            <div className="max-w-3xl mx-auto">
              {product.faqs.map((faq, index) => (
                <div key={index} className="mb-6">
                  <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
                  <p className="text-slate-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Remaining Services Section */}
      <div className="bg-blue-50 py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">
            Explore Our Other Services
          </h2>
          <p className="text-slate-600 max-w-2xl mx-auto mb-8">
            Discover our complete range of solutions designed to help your
            business grow
          </p>
          <div className="flex justify-center items-center">
            <Link to="/products" className="no-underline">
              <Button
                className="inline-block px-10 py-3 bg-blue-600 text-white font-medium rounded-xl shadow-md transition-all duration-200 hover:bg-blue-700 hover:shadow-lg"
                text={"View all Product"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Displayproduct;
