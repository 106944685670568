import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="text-gray-600 bg-gray-100 body-font">
      <div className="container px-4 py-16 mx-auto">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 lg:w-1/4 mb-8 md:mb-0">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CONTACT US</h2>
            <nav className="list-none mb-6">
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Vang Technologies Pvt. Ltd.</p>
              </li> 
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">1,2,3 Gandhinagar</p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Bangalore</p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Karnataka</p>
              </li>
              <li className="mb-3">
                <p className="text-gray-600 text-xs hover:text-gray-800">India-560009</p>
              </li>
            </nav>
            <a 
              href="https://maps.app.goo.gl/vepShMMUf8uKSnS79" 
              rel="noreferrer" 
              target='_blank' 
              className='px-3 py-2 rounded-lg text-xs hover:bg-gray-800 transition-colors duration-300 bg-black text-white inline-block'>
              Get Direction
            </a>
          </div>
          
          <div className="w-full md:w-1/3 lg:w-1/4 mb-8 md:mb-0">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">QUERIES</h2>
            <nav className="list-none mb-6">
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">For Product related queries</p>
              </li> 
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Please feel free to contact:</p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">
                  Arun Sharma: 
                  <a className='text-indigo-500 hover:text-indigo-400 ml-1' href='tel:9738425496'>9738425496</a>
                </p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">or mail us on:</p>
              </li>
              <li className="mb-1">
                <a href='mailto:info@vangtechnologies.in' className="text-indigo-500 text-xs hover:text-indigo-400">
                  info@vangtechnologies.in
                </a>
              </li>
            </nav>
          </div>
          
          <div className="w-full md:w-1/3 lg:w-1/4">
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">OUR SERVICES</h2>
            <nav className="list-none mb-6">
              <li className="mb-1">
                <Link to="/products-array/1" className="text-indigo-500 text-xs hover:text-indigo-400">
                  Front Office Management
                </Link>
              </li> 
              <li className="mb-1">
                <Link to="/products-array/2" className="text-indigo-500 text-xs hover:text-indigo-400">
                  Point of Sale
                </Link>
              </li>
              <li className="mb-1">
                <Link to="/products-array/4" className="text-indigo-500 text-xs hover:text-indigo-400">
                  MRP Management software
                </Link>
              </li>
            </nav>
            
            <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">ADDITIONAL BENEFITS</h2>
            <nav className="list-none mb-6">
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Inventory Management</p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Door Lock Integration</p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Power automation integration</p>
              </li>
              <li className="mb-1">
                <p className="text-gray-600 text-xs hover:text-gray-800">Channel Manager Integration</p>
              </li>
            </nav>
          </div>
        </div>
      </div>
      
      <div className="bg-gray-100 border-t border-gray-200">
        <div className="flex flex-col items-center w-full gap-y-4 py-3 px-5">
          <div className='flex flex-col items-center'>
          <Link to="/" className="">
            <img src='/Images/company-logo.svg' alt='Vang Technologies' height={60} width={60} />
          </Link>
          <p className="">
            © 2024 Vang Technologies —
            <a 
              href="https://www.facebook.com/people/Vang-Softwares/100010899492337" 
              rel="noopener noreferrer" 
              className="text-gray-600 ml-1" 
              target="_blank">
              @vangtechnologies
            </a>
          </p>
          </div>
          <span className="">
            <a 
              href='https://www.facebook.com/people/Vang-Softwares/100010899492337' 
              rel="noreferrer" 
              target="_blank" 
              className="text-gray-500 hover:text-gray-700">
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer