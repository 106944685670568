import React from "react";
import Testimonial from "./Testimonial";
import Funfacts from "./Funfacts";
import Statistics from "./Statistics";

function About() {
  const stats = [
    {
      number: "2016",
      text: "In Business Since"
    },
    {
      number: "100%",
      text: "Client Satisfaction"
    },
    {
      number: "500+",
      text: "Customers"
    },
  ];

  return (
    <div className="w-full bg-white">
      <div className="max-w-6xl mx-auto py-16 md:py-24 lg:py-32 px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="grid md:grid-cols-2 gap-8 lg:gap-12 items-center mb-16">
          <div className="space-y-4">
            <h1 className="font-mono text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900">
              We are <br /> Vang Technology
            </h1>
          </div>
          <div className="space-y-4">
            <p className="font-mono uppercase tracking-widest text-gray-600">About Us</p>
            <p className="text-sm md:text-base text-gray-700 leading-relaxed">
              Welcome to VANG Technologies, a premier provider of bespoke
              software solutions specifically designed for the hospitality
              industry. Our mission is to revolutionize how hospitality
              businesses operate by offering state-of-the-art technology that
              enhances efficiency, streamlines processes, and drives business
              growth. Our robust portfolio includes Front Office systems, Point
              of Sale (POS) solutions, Inventory Management, MRP Billing, and
              Bar Retail Software.
            </p>
          </div>
        </div>

        {/* SVG Illustration */}
        <div className="w-full flex justify-center my-12 md:my-16">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 800 400"
            className="w-full max-w-4xl h-auto"
          >
            {/* Office Building */}
            <rect x="100" y="100" width="600" height="250" fill="#f0f0f0" />
            
            {/* Windows */}
            <rect x="150" y="130" width="80" height="60" fill="#a0d8ef" />
            <rect x="270" y="130" width="80" height="60" fill="#a0d8ef" />
            <rect x="390" y="130" width="80" height="60" fill="#a0d8ef" />
            <rect x="510" y="130" width="80" height="60" fill="#a0d8ef" />
            
            <rect x="150" y="220" width="80" height="60" fill="#a0d8ef" />
            <rect x="270" y="220" width="80" height="60" fill="#a0d8ef" />
            <rect x="390" y="220" width="80" height="60" fill="#a0d8ef" />
            <rect x="510" y="220" width="80" height="60" fill="#a0d8ef" />
            
            {/* Door */}
            <rect x="340" y="280" width="70" height="70" fill="#555555" />
            
            {/* People */}
            <circle cx="200" cy="360" r="15" fill="#3a86ff" />
            <rect x="195" y="375" width="10" height="25" fill="#3a86ff" />
            
            <circle cx="450" cy="360" r="15" fill="#ff006e" />
            <rect x="445" y="375" width="10" height="25" fill="#ff006e" />
            
            <circle cx="550" cy="360" r="15" fill="#3a86ff" />
            <rect x="545" y="375" width="10" height="25" fill="#3a86ff" />
            
            {/* Trees */}
            <circle cx="150" cy="350" r="30" fill="#57cc99" />
            <rect x="145" y="350" width="10" height="30" fill="#38a3a5" />
            
            <circle cx="650" cy="350" r="30" fill="#57cc99" />
            <rect x="645" y="350" width="10" height="30" fill="#38a3a5" />
            
            {/* Company Name */}
            <text x="320" y="80" fontFamily="monospace" fontSize="24" fill="#333">VANG TECHNOLOGIES</text>
          </svg>
        </div>

        {/* Vision & Mission Section */}
        <div className="max-w-3xl mx-auto mb-16">
          <div className="space-y-8">
            <div>
              <h3 className="font-mono text-xl font-semibold mb-3 md:text-center">Our Vision</h3>
              <p className="text-gray-700 leading-relaxed md:text-center">
                At VANG Technologies, our vision is to be a transformative force in
                the hospitality sector, enabling businesses to achieve their full
                potential through innovative software solutions. We envision a
                future where technology empowers hospitality businesses to deliver
                unparalleled guest experiences while optimizing back-end operations.
              </p>
            </div>
            
            <div>
              <h3 className="font-mono text-xl font-semibold mb-3 md:text-center">Our Mission</h3>
              <p className="text-gray-700 leading-relaxed md:text-center">
                Our mission is to simplify the complexities of hospitality
                management, providing tools that allow businesses to focus on
                delivering exceptional guest experiences. We are committed to
                offering solutions that are functional, intuitive, scalable, and
                aligned with the ever-changing demands of the industry.
              </p>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="mb-16">
          <Statistics stats={stats} />
        </div>

        {/* Fun Facts */}
        <div className="mb-16">
          <Funfacts />
        </div>

        {/* Testimonials */}
        <div>
          <Testimonial />
        </div>
      </div>
    </div>
  );
}

export default About;