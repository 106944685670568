import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import Navbar from './Components/ui/Navbar';
import Footer from './Components/Footer';
import Home from "./Components/Home";
import Product from "./Components/Product";
import Displayproduct from "./Components/Displayproduct";
import CareerPage from './Components/Careers';
import Loader from "./Components/Loader";

export default function AppContent() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

function MainContent() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  
  // Access query parameters
  const [searchParams] = useSearchParams();

  // Log query parameters when location changes (for debugging)
  useEffect(() => {
    console.log('Current URL Parameters:');
    for (const [key, value] of searchParams.entries()) {
      console.log(`${key}: ${value}`);
    }
  }, [searchParams]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, [location.pathname]); // Runs on route change

  return (
    <div>
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Product />} />
            <Route path="/product-detail/:productId" element={<Displayproduct />} />
            <Route path='/careers' element={<CareerPage />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

// Example of how to use query parameters in a component:
function ExampleComponent() {
  const [searchParams] = useSearchParams();
  
  // Get specific parameters
  const utmSource = searchParams.get('utm_source');
  const utmCampaign = searchParams.get('utm_campaign');
  
  return (
    <div>
      {utmSource && <p>Source: {utmSource}</p>}
      {utmCampaign && <p>Campaign: {utmCampaign}</p>}
    </div>
  );
}