const Productarray = [
    {
        id: "1",
        img:"/Images/frnt-ofc.png",
        name: "Front Office Management",
        content: "This software streamlines hotel reception tasks, improving guest check-ins, reservations, and overall desk efficiency.",
        link:  "/product-detail/1"        
    },
    {
        id: "2",
        img:"/Images/ptofsale.png",
        name: "Point of Sale",
        content: "This software tracks restaurant orders, enhancing efficiency and accuracy for customer transactions.",
        link: "/product-detail/2",      
    },
    {
        id: "3",
        img:"/Images/mrp.png",
        name: "MRP Management software",
        content: "This software optimizes retail pricing, managing for products to ensure consistent and accurate pricing across stores.",
        link: "/product-detail/3"   
    }
]

export default Productarray