import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Button from "./ui/Button";
import Trust from "./Trust";
import About from "./About";
import Contact from "./Contactus";
import VangChatbot from "./Bot";
import { MessageCircle } from "lucide-react"; // Import the chat icon

function Home() {
  const [showChatbot, setShowChatbot] = useState(false);
  // Use ref to track if the toggle was from closing
  const isClosingAction = useRef(false);
  
  // Function to scroll to Contact component when "Get Free Demo" is clicked
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Modified toggle chatbot function
  const toggleChatbot = () => {
    // Track if we're closing the chatbot
    isClosingAction.current = showChatbot;
    setShowChatbot(!showChatbot);
  };

  // Handle the chatbot closing with a separate function
  const handleCloseChatbot = () => {
    isClosingAction.current = true;
    setShowChatbot(false);
    // Prevent scroll restoration or movement
    setTimeout(() => {
      isClosingAction.current = false;
    }, 100);
  };

  return (
    <>
      <div className="pt-20 md:pt-28 lg:pt-40 px-4 sm:px-6 md:px-8">
        <div className="flex flex-col gap-4 md:gap-6 justify-center items-center max-w-6xl mx-auto">
          <div>
            <p className="text-3xl sm:text-4xl md:text-6xl lg:text-[5rem] -tracking-[0.022em] leading-[1.2] text-inter font-medium text-center whitespace-pre-wrap website-custom-text pb-2">
              Struggling with hospitality <br className="hidden sm:block" /> management?
            </p>
            <p className="font-inter text-slate-700 font-[350] text-base sm:text-lg md:text-2xl -tracking-[0.017em] md:-tracking-[0.019em] text-inter antialiased max-w-5xl text-center mt-2 md:mt-4 mx-auto">
              Our solutions simplify operations like Front Office, POS,
              Inventory, and MRP while integrating seamlessly with door locks,
              power systems, and third-party tools
            </p>
          </div>
          <div className="flex flex-col items-center sm:flex-row w-full gap-y-4 sm:gap-x-10 justify-center mt-4">
            <Link to="/products" className="sm:w-auto w-full">
              <Button text="Explore Services" className="px-6 sm:px-8 py-2 sm:py-3 rounded-xl w-full sm:w-auto" />
            </Link>
            <Button 
              text="Get Free Demo" 
              className="px-6 sm:px-8 py-2 sm:py-3 rounded-xl w-full sm:w-auto"
              onClick={scrollToContact}
            />
          </div>
        </div>
        
        <Trust />
        
        <div className="flex flex-col items-center mt-10 md:mt-16 px-4">
          <p className="font-inter text-2xl sm:text-3xl font-bold text-center capitalize text-slate-900">
            All-in-one POS, MRP management, and front office solution for your
            outlets.
          </p>
          <p className="text-center font-inter text-base sm:text-lg font-semibold text-slate-500 mt-2 md:mt-4 max-w-4xl mx-auto">
            Effortlessly manage orders from multiple billing counters while
            handling high-volume online orders with ease.
          </p>
        </div>
        
        <About />
        <div id="contact-section">
          <Contact />
        </div>
      </div>

     </>
  );
}

export default Home;