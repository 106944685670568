import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Testimonialarray from './Testimonial-array';
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

function Testimonial() {
  const [current, setCurrent] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for next, -1 for previous

  const nextTestimonial = () => {
    setDirection(1);
    setCurrent((prev) => (prev === Testimonialarray.length - 1 ? 0 : prev + 1));
  };

  const prevTestimonial = () => {
    setDirection(-1);
    setCurrent((prev) => (prev === 0 ? Testimonialarray.length - 1 : prev - 1));
  };

  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-16">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="font-bold font-inter text-3xl md:text-4xl lg:text-5xl text-gray-900 relative inline-block">
            Testimonials
            <span className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-indigo-500 rounded"></span>
          </h2>
          <p className="mt-4 text-gray-600 max-w-2xl mx-auto text-sm md:text-base">
            See what our customers are saying about our services
          </p>
        </div>

        {/* Testimonial Card */}
        <div className="max-w-4xl mx-auto">
          <div className="bg-white rounded-xl shadow-lg p-6 md:p-8 lg:p-10 relative overflow-hidden">
            {/* Decorative elements */}
            <div className="absolute top-0 left-0 w-20 h-20 bg-indigo-100 rounded-br-full opacity-50"></div>
            <div className="absolute bottom-0 right-0 w-20 h-20 bg-indigo-100 rounded-tl-full opacity-50"></div>
            
            {/* Quote icon */}
            <div className="flex justify-center mb-6">
              <div className="w-12 h-12 md:w-16 md:h-16 bg-indigo-100 rounded-full flex items-center justify-center">
                <img
                  src="/Images/testimonial-img.svg"
                  alt="testimonial"
                  className="w-6 md:w-8 text-indigo-500"
                />
              </div>
            </div>
            
            {/* Testimonial content */}
            <div className="relative z-10 min-h-48">
              <AnimatePresence mode="wait" custom={direction}>
                <motion.div
                  key={Testimonialarray[current].id}
                  initial={{ y: direction * 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -direction * 20, opacity: 0 }}
                  transition={{ duration: 0.4, ease: "easeInOut" }}
                  className="flex flex-col items-center text-center"
                >
                  <p className="leading-relaxed text-gray-700 text-sm md:text-base lg:text-lg italic mb-6">
                    "{Testimonialarray[current].comment}"
                  </p>
                  
                  <div className="mt-4">
                    <div className="w-16 h-16 md:w-20 md:h-20 rounded-full overflow-hidden border-2 border-indigo-200 mx-auto">
                      {/* If you have user images, use them here */}
                      <div className="w-full h-full bg-indigo-200 flex items-center justify-center text-xl font-bold text-indigo-500">
                        {Testimonialarray[current].name.charAt(0)}
                      </div>
                    </div>
                    <h2 className="text-gray-900 font-semibold tracking-wider text-base md:text-lg mt-3">
                      {Testimonialarray[current].name}
                    </h2>
                    <p className="text-indigo-500 text-xs md:text-sm font-medium">{Testimonialarray[current].post}</p>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>

          {/* Pagination indicators */}
          <div className="flex justify-center mt-6 space-x-1">
            {Testimonialarray.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  setDirection(index > current ? 1 : -1);
                  setCurrent(index);
                }}
                className={`w-3 h-3 rounded-full transition-all duration-300 ${
                  index === current ? "bg-indigo-500 w-6" : "bg-gray-300"
                }`}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>

          {/* Navigation Buttons */}
          <div className="flex justify-center items-center mt-8 space-x-4">
            <button 
              className="w-10 h-10 md:w-12 md:h-12 bg-white rounded-full shadow-md flex items-center justify-center text-indigo-500 hover:bg-indigo-50 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-200"
              onClick={prevTestimonial}
              aria-label="Previous testimonial"
            >
              <GrLinkPrevious className="text-lg md:text-xl" />
            </button>
            <button 
              className="w-10 h-10 md:w-12 md:h-12 bg-white rounded-full shadow-md flex items-center justify-center text-indigo-500 hover:bg-indigo-50 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-200"
              onClick={nextTestimonial}
              aria-label="Next testimonial"
            >
              <GrLinkNext className="text-lg md:text-xl" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;