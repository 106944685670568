import React, { useState, useEffect } from 'react';

const VENUES = [
  { image: "/Images/costumer1.svg", alt: "SLV Royal Inn" },
  { image: "/Images/costumer2.svg", alt: "Ramya Residency" },
  { image: "/Images/costumer3.svg", alt: "Hotel ATG Royal Inn" },
  { image: "/Images/costumer4.svg", alt: "Hotel Shivani" },
  { image: "/Images/costumer5.svg", alt: "Hotel Malligi Hospet" },
  { image: "/Images/costumer6.svg", alt: "Rk Family Restaurants" },
  { image: "/Images/costumer7.svg", alt: "Samara's Veg" },
  { image: "/Images/costumer8.svg", alt: "Shobha Deluxe Lodge" },
  { image: "/Images/costumer9.svg", alt: "ScotchYard Liquor Boutiques" },
  { image: "/Images/costumer10.svg", alt: "HSG" },
  { image: "/Images/costumer11.svg", alt: "HLV Grand Inn" },
  { image: "/Images/costumer12.svg", alt: "Unique Liquor" },
  { image: "/Images/costumer13.svg", alt: "Buzz" },
];

export default function Trust() {
  const [venuesToShow, setVenuesToShow] = useState([]);

  useEffect(() => {
    setVenuesToShow(VENUES);
  }, []);

  return (
    <div className="py-16 flex items-center flex-col w-full gap-4">
      <h3 className="font-inter font-semibold">Trusted By</h3>
      <div className="w-full inline-flex flex-nowrap overflow-hidden md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-4 [&_li]:md:mx-8 [&_img]:max-w-none animate-infinite-scroll">
          {venuesToShow.map((venue, index) => (
            <li key={index}>
              <img src={venue.image} alt={venue.alt} className="rounded-full grayscale w-[100px]" />
            </li>
          ))}
        </ul>
        <ul
          className="flex items-center justify-center md:justify-start [&_li]:mx-4 [&_li]:md:mx-8 [&_img]:max-w-none animate-infinite-scroll"
          aria-hidden="true"
        >
          {venuesToShow.map((venue, index) => (
            <li key={index}>
              <img src={venue.image} alt={venue.alt} className="rounded-full grayscale w-[100px]" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}