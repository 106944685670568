import React from "react";
function Statistics({stats}) {
  return (
    <div className="flex justify-between max-w-5xl mx-auto gap-y-5 gap-x-6 w-full px-3 py-4">
      {stats.map((items,index)=>(
        <>
        <div className="flex flex-col" key={index}>
          <span className="md:text-3xl text-2xl font-bold font-inter website-custom-text">{items.number}</span>
          <span className="md:text-lg text-xs text-gray-600 font-inter">{items.text}</span>
        </div>
        </>
      ))}
    </div>
  );
}

export default Statistics;
