const Testimonialarray = [
  {
    id: "1",
    name: "Kiran Gowda",
    comment:
      "VANG Technologies streamlines hospitality operations with specialized software, enhancing efficiency and growth.",
    post: "Owner of Hotel ATG Royal Inn",
    places: "Hotel ATG Royal Inn",
  },
  {
    id: "2",
    name: "Tejas Gowda",
    comment:
      "Their tailored software and seamless integrations optimize hospitality management with a customer-centric approach.",
    post: "Owner of UT Group",
    places: "UT Group",
  },
  {
    id: "3",
    name: "Bharat Gowda",
    comment:
      "VANG Technologies delivers innovative, quality-driven solutions, helping businesses thrive in the hospitality industry.",
    post: "Owner of Tirupathi Group",
    places: "Tirupathi Group",
  },
];

export default Testimonialarray;
