import React, { useState, useEffect, useRef } from "react";
import { Phone, Menu, X, ArrowRight } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const navLinks = [
    { link: "About Us", href: "/" },
    { link: "Services", href: "/products" },
    { link: "Careers", href: "/careers" },
  ];

  const location = useLocation();
  const [activePath, setActivePath] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const phoneNumber = "9738425496";

  // Update active path whenever location changes
  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  // Handle outside clicks for mobile menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  // Add body scroll lock when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      // Disable scrolling on body
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      // Re-enable scrolling on body when menu closes
      const scrollY = document.body.style.top;
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      
      // Restore scroll position
      if (scrollY) {
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    }
    
    return () => {
      // Cleanup in case component unmounts while menu is open
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
    };
  }, [isMenuOpen]);

  return (
    <div className="fixed px-4 sm:px-8 lg:px-20 flex w-full items-center justify-between shadow-sm bg-white z-30 h-16">
      <Link to="/">
        <img src="/Images/company-logo.svg" alt="Company Logo" width={60} />
      </Link>

      {/* Desktop Nav */}
      <div className="hidden md:flex gap-6 dark:text-white text-black">
        {navLinks.map((navItem, index) => (
          <Link
            key={index}
            to={navItem.href}
            className={`px-2 py-1 rounded-full font-normal text-[14px] tracking-[-0.006em] leading-5 font-inter text-black no-underline ${
              activePath === navItem.href
                ? "border-b border-website-custom-color"
                : ""
            }`}
          >
            {navItem.link}
          </Link>
        ))}
      </div>

      {/* Desktop CTA */}
      <div className="hidden md:flex flex-row-reverse items-center gap-4 ">
        <a
          href={`tel:${phoneNumber.replace(/\D/g, "")}`}
          className="flex items-center group no-underline"
        >
          <div className="border border-[#536868] p-2 rounded-full cursor-pointer hover:translate-y-1 transition-transform duration-300">
            <Phone size={20} className="text-[#536868] dark:text-white" />
          </div>
          <div className="flex flex-col ml-2">
            <span className="text-xs text-[#909898]">Call us</span>
            <span className="text-sm text-[#536868] font-medium">
              {phoneNumber}
            </span>
          </div>
        </a>
        <div className="text-[#909898] mb-0 dark:text-white font-inter text-sm tracking-[-0.006em] px-4 py-2 bg-transparent border border-[#909898] rounded-xl cursor-pointer hover:bg-gray-50 transition-colors duration-300 flex items-center">
          Get a Demo
          <ArrowRight size={16} className="ml-2" />
        </div>
      </div>

      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center">
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="focus:outline-none border border-[#536868] p-1.5 rounded-lg"
          aria-label="Toggle menu"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Semi-transparent Overlay */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black/50 md:hidden z-30"
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}

      {/* Mobile Menu - Simplified and Clearer */}
      <div
        ref={menuRef}
        className={`fixed top-0 right-0 w-4/5 h-full bg-white shadow-lg flex flex-col items-start py-6 px-6 md:hidden transition-transform duration-300 ease-in-out z-40 ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <button
          onClick={() => setIsMenuOpen(false)}
          className="self-end mb-8 border border-[#536868] p-1.5 rounded-lg"
        >
          <X size={24} />
        </button>

        {/* Simplified Navigation Links */}
        <div className="w-full space-y-6">
          {navLinks.map((navItem, index) => (
            <Link
              key={index}
              to={navItem.href}
              className={`block w-full text-black font-inter text-xl py-3 px-4 border-l-4 no-underline ${
                activePath === navItem.href
                  ? "border-l-[#536868] bg-gray-50 font-medium"
                  : "border-l-transparent"
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              {navItem.link}
            </Link>
          ))}
        </div>

        {/* Mobile Demo Button with Arrow */}
        <div className="mt-6 w-full md:block hidden">
          <div className="text-[#536868] font-inter text-lg py-3 px-4 border border-[#536868] rounded-lg flex items-center justify-between cursor-pointer">
            Get a Demo
            <ArrowRight size={20} />
          </div>
        </div>

        {/* Call for Demo - Added at bottom with spacer */}
        <div className="mt-auto pt-6 w-full flex items-center">
          <a
            href={`tel:${phoneNumber.replace(/\D/g, "")}`}
            className="flex items-center no-underline"
            aria-label="Call for demo"
          >
            <div className="border border-[#536868] p-3 rounded-full">
              <Phone size={24} className="text-[#536868]" />
            </div>
            <div className="ml-3 text-[#536868] font-medium">
              Call for demo
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}