import React from "react";
import Productarray from "./Products-array";
import { Link } from "react-router-dom";
import Contact from "./Contactus"

function Product() {
  return (
    <section className="py-20 md:py-24 bg-gradient-to-b from-white to-slate-50">
      <div className="container mx-auto px-4">
        {/* Header Section */}
        <div className="max-w-4xl mx-auto mb-16 text-center">
          <span className="inline-block mb-4 px-4 py-1.5 text-xs font-medium tracking-wider uppercase bg-blue-50 text-blue-600 rounded-full transition-all">
            WHY CHOOSE US
          </span>
          <h2 className="mb-4 text-4xl sm:text-5xl md:text-6xl font-bold leading-tight text-gray-900">
            Transform Your Operations
          </h2>
          <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
            Power Up with VANG Technologies' Cutting-Edge Services
          </p>
        </div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {Productarray.map((product, index) => (
            <Link 
              to={product?.link} 
              key={index}
              className="no-underline group"
            >
              <div className="h-full overflow-hidden bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100">
                <div className="relative">
                  <img 
                    className="w-full transition-transform duration-500 group-hover:scale-105" 
                    src={product?.img} 
                    alt={product?.name} 
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </div>
                
                <div className="p-5">
                  <h3 className="font-semibold text-lg text-gray-900 mb-2 group-hover:text-blue-600 transition-colors">
                    {product?.name}
                  </h3>
                  <p className="text-gray-600 text-sm">
                    {product?.content}
                  </p>
                  
                  <div className="mt-4 flex items-center">
                    <span className="text-sm text-blue-600 font-medium group-hover:underline">
                      Learn more
                    </span>
                    <svg className="w-4 h-4 ml-1 text-blue-600 transform group-hover:translate-x-1 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Product;