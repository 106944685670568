import React from "react";

const Button = ({
  className = "",
  text,
  type = "button",
  disabled = false,
  onClick,
  svg = null,
  svgPosition = "left", // can be "left" or "right"
}) => {
  return (
    <button
      type={type}
      className={`bg-gradient-to-r from-[#0152FE] to-[#028BFA] text-white font-inter font-bold
             transition-colors duration-300 hover:from-[#0152FE] hover:to-[#0152FE] 
             flex items-center justify-center gap-2 sm:gap-2
             text-xs sm:text-sm md:text-base lg:text-lg
             rounded-md sm:rounded-lg ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {svg && svgPosition === "left" && (
        <span>{svg}</span>
      )}
      <span className="truncate">{text}</span>
      {svg && svgPosition === "right" && (
        <span>{svg}</span>
      )}
    </button>
  );
};

export default Button;