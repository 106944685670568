import React from "react";
import { SyncLoader } from "react-spinners";

function Loader() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <SyncLoader color="#028BFA" />
    </div>
  );
}

export default Loader;
